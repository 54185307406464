@font-face {
  font-family: 'Noto Sans Display';
  font-style: italic;
  font-weight: 200;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansdisplay/v26/RLpZK4fy6r6tOBEJg0IAKzqdFZVZxrktbnDB5UzBIup9PwAcHtEsOFNBZqyu6r9JPXKa3g.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Display';
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansdisplay/v26/RLpZK4fy6r6tOBEJg0IAKzqdFZVZxrktbnDB5UzBIup9PwAcHtEsOFNBZqyu6r9JvXKa3g.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Display';
  font-style: normal;
  font-weight: 200;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansdisplay/v26/RLpbK4fy6r6tOBEJg0IAKzqdFZVZxpMkXJMhnB9XjO1o90LuV-PT4Doq_AKp__cKVTE.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Display';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansdisplay/v26/RLpbK4fy6r6tOBEJg0IAKzqdFZVZxpMkXJMhnB9XjO1o90LuV-PT4Doq_AKp_3cKVTE.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans Display';
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosansdisplay/v26/RLpbK4fy6r6tOBEJg0IAKzqdFZVZxpMkXJMhnB9XjO1o90LuV-PT4Doq_AKp_5ANVTE.ttf) format('truetype');
}
